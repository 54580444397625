import './index.scss'
import { Link, NavLink } from 'react-router-dom'
import LogoD from '../../assets/images/logo-d.png'
import LogoSubtitle from '../../assets/images/logo_subd.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faEnvelope, faCode } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Sidebar = () => (
  <div className='nav-bar'>
    <Link className='logo' to='/'>
      <img src={LogoD} alt='logo' />
      <img className='sub-logo' src={LogoSubtitle} alt='slobodan' />
    </Link>
    <nav>
      <NavLink exact='true' activeclassname='active' to='/'>
        <FontAwesomeIcon icon={faHome} color='#4d4d4e' />
      </NavLink>
      <NavLink exact='true' activeclassname='active' className='about-link' to='/about'>
        <FontAwesomeIcon icon={faUser} color='#4d4d4e' />
      </NavLink>
      <NavLink exact='true' activeclassname='active' className='projects-link' to='/projects'>
        <FontAwesomeIcon icon={faCode} color='#4d4d4e' />
      </NavLink>
      <NavLink exact='true' activeclassname='active' className='contact-link' to='/contact'>
        <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e' />
      </NavLink>
    </nav>
    <ul>
      <li>
        <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/daniel-dominguez-arroyo/'>
          <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e' />
        </a>
      </li>
      <li>
        <a target='_blank' rel='noreferrer' href='https://github.com/danieldom02'>
          <FontAwesomeIcon icon={faGithub} color='#4d4d4e' />
        </a>
      </li>
    </ul>
  </div>
)

export default Sidebar
